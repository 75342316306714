import React from 'react'
import { Link } from 'gatsby'
import Layout from 'src/components/Layout'
import SEO from 'src/components/Seo'

const NotFoundPage: React.FC = () => (
  <>
    <SEO title="404: Not found" />
    <Layout>
      <h1>404: Not Found</h1>
      <p>このページは表示できません</p>
      <p>這個網址無法顯示</p>
      <p>
        <Link to="/">Return to Homepage</Link>
      </p>
    </Layout>
  </>
)

export default NotFoundPage
